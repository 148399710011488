<script lang="ts">
	import cn from 'classnames';
	import { page } from '$app/stores';
	import { browser } from '$app/environment';
	import { scooterStorage } from '../stores/preferences';
	import { cartStorage } from '../stores/preferences';
	import logo_main from '$lib/images/logo_main.webp';
	import CloseIcon from '$lib/images/svg/close.svelte';
	import CartIcon from '$lib/images/svg/cart.svelte';
	import MenuIcon from '$lib/images/svg/menu.svelte';
	import EnvelopeIcon from '$lib/images/svg/envelope.svelte';
	import ScooterIcon from '$lib/images/svg/scooter.svelte';
	import SearchIcon from '$lib/images/svg/search.svelte';
	import ParametersIcon from '$lib/images/svg/parameters.svelte';
	import GraphIcon from '$lib/images/svg/graph.svelte';
	import PlayIcon from '$lib/images/svg/play.svelte';
	import GearIcon from '$lib/images/svg/gear.svelte';
	import CurrencySwitch from './(components)/CurrencySwitch.svelte';
	import AnimatedCounter from './(components)/AnimatedCounter.svelte';
	import ScooterSelector from './(components)/ScooterSelector.svelte';

	export let disabledScroll = false;
	export let currentPrice = 0;

	let isMenu = false;
	let isSubMenu = false;
	let intValues = [];

	intValues.push(currentPrice);
	intValues.push(0);

	function toggleMenu() {
		isMenu = !isMenu;
		isSubMenu = false;
		disabledScroll = isMenu;
	}

	if (browser) {
		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				isMenu = false;
				disabledScroll = false;
			}
		});
	}

</script>

{#if $page.route.id?.indexOf('/administration') === -1 || $page.route.id === null}
	<header
		class={cn(
			'bg-main-full-image xlg:bg-center relative z-40 flex w-full flex-col items-center justify-between bg-cover bg-[64%_center] bg-no-repeat px-3',
			$page.route.id === '/' ? 'min-h-[34.375rem] lg:h-[43.75rem]' : ''
		)} 
	>
		<div
			class="container mx-auto flex w-full flex-wrap items-center justify-between py-4 text-white lg:flex-row lg:justify-start lg:px-0 lg:py-8"
		>
			<a href="/" title="home" class={cn('flex w-44 lg:mr-6 xl:w-72', isMenu && 'hidden')}>
				<img src={logo_main} class="block w-full" alt="Scootland.cz" />
			</a>

			<div class="flex flex-row gap-x-6 md:gap-x-8">
				<a
					href="/vyhledavani/"
					title="vyhledávání"
					on:click={() => isMenu = false}
					class={cn(
						'z-50 flex cursor-pointer flex-col items-center gap-x-2 text-center text-white lg:hidden',
						isMenu && 'hidden'
					)}
				>
					<SearchIcon class="mb-1 h-5 text-gray-400" />
					<span class="text-xs">Hledat</span>
				</a>

				<a
					href="/kosik/"
					class={cn(
						'flex cursor-pointer flex-col items-center text-center text-white lg:hidden',
						isMenu && 'hidden'
					)}
				>
					<div class="relative mb-1">
						<CartIcon class="fill-gray mx-auto h-5 text-gray-400" />
						<div
							class="absolute -right-3 -top-3 flex h-5 w-5 items-center justify-center rounded-full bg-red text-xs text-white"
						>
							{#if $cartStorage?.new_articles?.lines?.length > 0 || $cartStorage?.used_articles?.lines?.length > 0}
								{$cartStorage?.new_articles?.lines?.length +
									$cartStorage?.used_articles?.lines?.length}
							{:else}
								0
							{/if}
						</div>
					</div>
					<span class="text-xs">Košík</span>
				</a>

				<button
					on:click={toggleMenu}
					class="z-50 flex cursor-pointer flex-col items-center gap-x-2 text-center text-white lg:hidden"
				>
					{#if isMenu}
						<CloseIcon
							class={cn('fill-gray relative z-50 mx-auto mb-1 inline-block h-5 text-gray-400')}
						/>
					{:else}
						<MenuIcon
							class={cn('fill-gray relative z-50 mx-auto mb-1 h-5 -translate-y-0.5 text-gray-400')}
						/>
					{/if}
					<span class="text-xs">Menu</span>
				</button>
			</div>

			<div
				class="hidden min-h-9 flex-row items-center gap-x-3 border-l border-white border-opacity-25 pl-6 lg:flex"
			>
				<EnvelopeIcon class="h-4 text-gray-400" />
				<a href="mailto:info@scootland.cz" class="hover:underline">info@scootland.cz</a>
			</div>

			<div
				class={cn(
					'relative z-50 mt-0 flex w-full items-center justify-center lg:ml-auto lg:w-auto lg:justify-start lg:pl-6',
					$page.route.id === '/' && 'hidden lg:flex lg:w-auto',
					isMenu && 'lgmax:hidden'
				)}
			>
				{#if $scooterStorage?.url != null && $scooterStorage?.url !== ''}
					<div class="relative mt-3 w-full lg:mr-5 lg:mt-0 lg:w-auto" >
						{#if !isMenu}
							<div on:mouseenter={() => (isSubMenu = true)} on:mouseleave={() => (isSubMenu = false)} >
							<button
								
								class="relative flex w-full flex-row justify-center gap-x-2 bg-yellow px-5 py-2 text-center text-base text-gray-shark"
							>
								<ScooterIcon class="w-6" />
								{$scooterStorage?.producer}
								{$scooterStorage?.name}
							</button>

						<div
							
							class:hidden={!isSubMenu}
							class="custom-skutr absolute top-full flex w-full cursor-pointer border-2 bg-white text-center text-gray-shark lg:text-left"
						>
							<a
								href="/skutr-{$scooterStorage?.url}/"
								class="w-full p-2 transition hover:bg-gray-100"
							>
								Náhradní díly
							</a>
							<a
								href="/skutr-{$scooterStorage?.url}/technicke-udaje/"
								class="w-full p-2 transition hover:bg-gray-100"
							>
								Technické parametry
							</a>
							{#if $scooterStorage.hasArticles}
								<a
									href="/skutr-{$scooterStorage?.url}/blog/"
									class="w-full p-2 transition hover:bg-gray-100"
								>
									Návody a tipy
								</a>
							{/if}
							{#if $scooterStorage.hasGraphs}
								<a
									href="/skutr-{$scooterStorage?.url}/zvyseni-vykonu/"
									class="w-full p-2 transition hover:bg-gray-100"
								>
									Grafy
								</a>
							{/if}
							<a
								href="/skutry/"
								data-sveltekit-preload-data="tap"
								class="w-full p-2 transition hover:bg-gray-100"
							>
								Najít jiný
							</a>
						</div>
							</div>
						{/if}
							
					</div>
				{:else}
					<div class="relative mt-3 w-full lg:mr-5 lg:mt-0 lg:w-auto">
						<a
							href="/skutry/"
							on:click={() => (isMenu ? (isMenu = false) : null)}
							data-sveltekit-preload-data="tap"
							class="relative flex w-full flex-row justify-center gap-x-2 bg-yellow px-5 py-2 text-center text-base text-gray-shark"
						>
							<ScooterIcon class="w-6" />
							<span class="whitespace-nowrap">Zvolit váš skútr</span>
						</a>
					</div>
				{/if}

				<a
					on:click={() => (isMenu ? (isMenu = false) : null)}
					href="/vyhledavani/"
					title="vyhledavani"
					on:click={() => disabledScroll = false}
					class={cn('z-50 hidden cursor-pointer flex-row items-center gap-x-2 text-white lg:flex')}
				>
					<SearchIcon class="h-5 text-gray-400" />
					<span>Hledat</span>
				</a>

				<a
					href="/kosik/"
					on:click={() => {
						isMenu ? (isMenu = false) : null ;
						disabledScroll = false}}
					class={cn(
						'ml-6 hidden min-h-9 flex-row items-center gap-x-5 pl-6 text-center text-white lg:flex lg:border-white lg:border-opacity-25',
						isMenu ? 'lg:border-0' : 'lg:border-l'
					)}
				>
					<div class="relative mb-1">
						<CartIcon class="fill-gray mx-auto h-5 text-gray-400" />

						<div
							class="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-red text-xs text-white"
						>
							{#if $cartStorage?.new_articles?.lines?.length > 0 || $cartStorage?.used_articles?.lines?.length > 0}
								{$cartStorage?.new_articles?.lines?.length +
									$cartStorage?.used_articles?.lines?.length}
							{:else}
								0
							{/if}
						</div>
					</div>
					{#if $cartStorage?.new_articles?.lines?.length > 0 || $cartStorage?.used_articles?.lines?.length > 0}
						<span>
							<AnimatedCounter />
						</span>
					{/if}
				</a>

				<button
					on:click={toggleMenu}
					class="ml-6 hidden min-h-9 cursor-pointer items-center gap-x-2 border-l border-white border-opacity-50 pl-6 text-white lg:flex"
				>
					<MenuIcon class="{isMenu ? 'hidden' : 'block'} h-4 text-gray-400" />
					<CloseIcon class="{isMenu ? 'block' : 'hidden'} float-left h-5 text-gray-400" />
					<span>Menu</span>
				</button>
			</div>

			<!-- Menu -->

			<div
				data-test="hidden-menu"
				class={cn(
					isMenu ? 'w-full' : 'w-0',
					'nav-menu fixed bottom-0 right-0 top-0 z-20 block h-full min-h-screen overflow-y-auto overflow-x-hidden bg-gray-shark text-gray-400 shadow-lg transition-all duration-300 ease-in-out'
				)}
			>
				<section class="body-font text-gray-600">
					<div
						class="container mx-auto items-center px-3 pt-16 text-white lg:flex lg:flex-wrap lg:pt-40"
					>
						<div
							class="mb-12 flex flex-col items-center justify-center border-b border-gray-light py-12 lg:mb-0 lg:w-1/2 lg:items-start lg:border-b-0 lg:border-r lg:border-gray-light lg:py-8 lg:pr-40 lg:text-xl"
						>
							{#if $scooterStorage?.url != null && $scooterStorage?.url !== ''}
								<a
									href="/skutr-{$scooterStorage?.url}/"
									on:click={() => disabledScroll = false}
									class="hover:bg-redHover mb-4 inline-flex w-full max-w-96 gap-x-7 bg-yellow px-6 py-4 text-2xl font-bold text-gray-shark transition-all"
								>
									<ScooterIcon class="w-8" />
									<span
										>{$scooterStorage?.producer}, {$scooterStorage?.ccm} ccm, {$scooterStorage?.model}</span
									>
								</a>

								<a
									href="/skutr-{$scooterStorage?.url}/"
									on:click={toggleMenu}
									title="náhradní díly"
									class="text-medium flex items-center gap-x-6 whitespace-nowrap py-2 text-white transition-all"
								>
									<GearIcon class="w-5 opacity-50" />
									Náhradní díly
								</a>

								<a
									href="/skutr-{$scooterStorage?.url}/technicke-udaje/"
									title="technické parametry"
									on:click={toggleMenu}
									class="text-medium flex items-center gap-x-6 whitespace-nowrap py-2 text-white transition-all"
								>
									<ParametersIcon class="w-5 opacity-50" />
									Technické parametry
								</a>

								{#if $scooterStorage.hasGraphs}
									<a
										href="/skutr-{$scooterStorage?.url}/zvyseni-vykonu/"
										title="grafy"
										on:click={toggleMenu}
										class="text-medium flex items-center gap-x-6 whitespace-nowrap py-2 text-white transition-all"
									>
										<GraphIcon class="w-5 opacity-50" />
										Grafy
									</a>
								{/if}

								{#if $scooterStorage.hasArticles}
									<a
										href="/skutr-{$scooterStorage?.url}/blog/"
										title="Návody a tipy"
										on:click={toggleMenu}
										class="text-medium flex items-center gap-x-6 whitespace-nowrap py-2 text-white transition-all"
									>
										<PlayIcon class="w-5 opacity-50" />
										Návody a tipy
									</a>
								{/if}

								<a
									href="/skutry/"
									on:click={toggleMenu}
									class="text-medium flex items-center gap-x-6 whitespace-nowrap py-2 text-white transition-all"
								>
									<SearchIcon class="w-5 opacity-50" />
									Zvolit jiný skútr
								</a>
							{:else}
								<div class="mb-10 w-full max-w-96 text-center lg:text-left">
									Chcete zobrazit náhradní díly, technické údaje, návody a videa pro konkrétní
									skútr?
								</div>

								<a
									href="/skutry/"
									data-sveltekit-preload-data="tap"
									on:click={toggleMenu}
									class="mb-4 flex items-center gap-x-7 whitespace-nowrap bg-yellow px-6 py-4 align-middle text-lg font-bold text-gray-shark transition-all hover:underline lg:text-2xl"
								>
									<ScooterIcon class="w-7" />
									Vybrat konkrétní skútr
								</a>
							{/if}
						</div>

						<div
							class="relative flex flex-col items-center text-center lg:w-1/2 lg:items-start lg:py-8 lg:pl-40 lg:text-left"
						>
							<nav class="-mb-1 list-none">
								<ul class="flex flex-col gap-y-4 lg:gap-y-6">
									<li>
										<a
											on:click={toggleMenu}
											href="/univerzalni-dily/"
											class="text-xl font-bold transition-all hover:underline lg:text-3xl"
										>
											Univerzální díly</a
										>
									</li>

									<li>
										<a
											on:click={toggleMenu}
											href="/brno/servis-brno/"
											rel="prefetch"
											class="text-xl font-bold transition-all hover:underline lg:text-3xl">Servis</a
										>
									</li>

									<li>
										<a
											on:click={toggleMenu}
											href="/brno/"
											rel="prefetch"
											class="text-xl font-bold transition-all hover:underline lg:text-3xl"
											>Prodejna</a
										>
									</li>

									<li>
										<a
											on:click={toggleMenu}
											href="/scooter-tuning-jaknato/"
											rel="prefetch"
											class="text-xl font-bold transition-all hover:underline lg:text-3xl"
											>Návody a tipy</a
										>
									</li>

									<li>
										<a
											on:click={toggleMenu}
											href="/kontakt/"
											rel="prefetch"
											class="text-xl font-bold transition-all hover:underline lg:text-3xl"
											>Kontakt</a
										>
									</li>
								</ul>
							</nav>
						</div>
					</div>

					<CurrencySwitch isMobile={false} class="mt-44 hidden lg:flex" />
				</section>
			</div>
			{#if isMenu}
				<CurrencySwitch isMobile class="z-50 lg:hidden" />
			{/if}
		</div>

		<!-- Homepage scooter selector -->
		{#if $page.route.id === '/'}
			<section class="container relative mx-auto text-center text-white lg:text-left">
				<ScooterSelector />
			</section>
		{/if}

		<!-- Homepage additional menu -->
		{#if $page.route.id === '/'}
			<section
				class={cn(
					'container relative mx-auto mb-6 text-center text-white md:mb-7 lg:text-left',
					$page.route.id === '/' && 'mt-12'
				)}
			>
				<div
					class="flex w-full flex-row justify-around gap-x-4 text-center text-sm text-white md:justify-start md:text-base"
				>
					<a
						href="/brno/servis-brno/"
						title="Objednání na servis online"
						class="md:border-r md:border-white md:border-opacity-25 md:pr-4">Objednání na servis</a
					>
					<a
						href="/skutry/jak-dobre-koupit-skutr/"
						title="Návody a tipy jak dobře koupit scooter"
						class="md:border-r md:border-white md:border-opacity-25 md:pr-4"
					>
						Kupujete skútr?</a
					>
					<a href="/brno/historie/" title="Co je scootland?" class=""> Co je Scootland?</a>
				</div>
			</section>
		{/if}
	</header>
{/if}
