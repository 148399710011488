<script lang="ts">
	import { page } from '$app/stores';
	import footer_payments from '$lib/images/payments.webp';
	import { version } from '$app/environment';
	import Heading from './(components)/Heading.svelte';
	import Dots from '$lib/images/svg/dots.svelte';
	import ChevronDown from '$lib/images/svg/chevron-down.svelte';
	import cn from 'classnames';
	import Phone from '$lib/images/svg/phone.svelte';
	import Envelope from '$lib/images/svg/envelope.svelte';
	import Facebook from '$lib/images/svg/facebook.svelte';

	let activeFooterItem: string | null = null;
</script>

{#if $page.route.id?.indexOf('/administration') === -1 || $page.route.id === null}
	<footer class="mt-auto block bg-gray-shark px-2.5 py-10 text-white sm:py-0 2xl:px-0">
		<div
			class="container mx-auto mb-12 flex flex-col items-center sm:mb-0 sm:grid sm:grid-cols-2 sm:items-start sm:gap-8 sm:px-0 sm:py-16 md:grid-cols-4"
		>
			<!-- SHOP -->
			<div class="w-full border-b border-white border-opacity-10 sm:border-0">
				<div
					class="flex cursor-pointer items-center gap-2.5 py-3 leading-none sm:flex-col sm:items-start sm:p-0"
					on:click={() =>
						activeFooterItem === 'shop' ? (activeFooterItem = null) : (activeFooterItem = 'shop')}
					on:keydown
					on:keypress
					on:keyup
				>
					<Heading
						tag="strong"
						class={cn(
							'text-xl transition-colors sm:text-2xl',
							activeFooterItem === 'shop' ? 'text-yellow' : 'text-white'
						)}>Nakupování</Heading
					>
					<Dots
						class={cn(
							'h-2 smmax:translate-y-[0.35rem]',
							activeFooterItem === 'shop' ? 'text-white' : 'text-yellow'
						)}
					/>
					<ChevronDown
						class={cn(
							'ml-auto w-4 text-white transition-transform sm:hidden',
							activeFooterItem === 'shop' && 'rotate-180'
						)}
					/>
				</div>

				<ul
					class={cn(
						'my-4 flex-col gap-y-3 sm:!flex',
						activeFooterItem === 'shop' ? 'flex' : 'hidden'
					)}
				>
					<li><a href="/eshop/podminky/" title="Obchodní podmínky firmy Scootland" class="hover:underline">Obchodní podmínky</a></li>
					<li><a href="/reklamace/" title="Reklamace a vrácení zboží - návod jak postupovat" class="hover:underline">Reklamace</a></li>
					<li>
						<a
							href="/eshop/zasielame-na-slovensko/"
							title="Zasíláme na Slovensko do 24 hodin kurýrní službou"
							class="hover:underline">Zasíláme na Slovensko</a
						>
					</li>
				</ul>
			</div>

			<!-- SERVICES -->
			<div class="w-full border-b border-white border-opacity-10 sm:border-0">
				<div
					class="flex cursor-pointer items-center gap-2.5 py-3 leading-none sm:flex-col sm:items-start sm:p-0"
					on:click={() =>
						activeFooterItem === 'services'
							? (activeFooterItem = null)
							: (activeFooterItem = 'services')}
					on:keydown
					on:keypress
					on:keyup
				>
					<Heading
						tag="strong"
						class={cn(
							'text-xl transition-colors sm:text-2xl',
							activeFooterItem === 'services' ? 'text-yellow' : 'text-white'
						)}>Služby</Heading
					>
					<Dots
						class={cn(
							'h-2 smmax:translate-y-[0.35rem]',
							activeFooterItem === 'services' ? 'text-white' : 'text-yellow'
						)}
					/>
					<ChevronDown
						class={cn(
							'ml-auto w-4 text-white transition-transform sm:hidden',
							activeFooterItem === 'services' && 'rotate-180'
						)}
					/>
				</div>

				<ul
					class={cn(
						'my-4 flex-col gap-y-3 sm:!flex',
						activeFooterItem === 'services' ? 'flex' : 'hidden'
					)}
				>
					<li>
						<a href="/rezervace/" title="objednat na servis online" class="hover:underline"
							>Objednat skútr na servis online</a
						>
					</li>
					<li>
						<a href="/brno/servis-brno/" class="hover:underline" title="Servis skútru Scootland Brno">Servis</a>
					</li>
					<li>
						<a href="/brno/" class="hover:underline" title="Prodejna Brno: Náhradní díly, doplňky a příslušenství pro skútry Aprilia, Honda, Piaggio, Yamaha, Malaguti, Gilera, Keeway, Kentoya, Maxon, CPI apod.">Prodejna</a>
					</li>

					<li><a href="/eshop/" title="Eshop na náhradní díly na skútry všech značek. Rychlé dodání a zboží skladem." class="hover:underline">Eshop</a></li>
				</ul>
			</div>

			<!-- USEFUL -->
			<div class="w-full border-b border-white border-opacity-10 sm:border-0">
				<div
					class="flex cursor-pointer items-center gap-2.5 py-3 leading-none sm:flex-col sm:items-start sm:p-0"
					on:click={() =>
						activeFooterItem === 'useful'
							? (activeFooterItem = null)
							: (activeFooterItem = 'useful')}
					on:keydown
					on:keypress
					on:keyup
				>
					<Heading
						tag="strong"
						class={cn(
							'text-xl transition-colors sm:text-2xl',
							activeFooterItem === 'useful' ? 'text-yellow' : 'text-white'
						)}>Užitečné</Heading
					>
					<Dots
						class={cn(
							'h-2 smmax:translate-y-[0.35rem]',
							activeFooterItem === 'useful' ? 'text-white' : 'text-yellow'
						)}
					/>
					<ChevronDown
						class={cn(
							'ml-auto w-4 text-white transition-transform sm:hidden',
							activeFooterItem === 'useful' && 'rotate-180'
						)}
					/>
				</div>

				<ul
					class={cn(
						'my-4 flex-col gap-y-3 sm:!flex',
						activeFooterItem === 'useful' ? 'flex' : 'hidden'
					)}
				>
					<li>
						<a href="/kontakt/" class="hover:underline" title="Kontaktujte Scootland. Veškeré kontaktní údaje jako email, telefon a otevírací doba">Kontakt</a>
					</li>
					<li>
						<a
							href="/skutry/jak-dobre-koupit-skutr/"
							class="hover:underline"
							title="Rady a tipy jak dobře vybrat skútr a nenapálit se">Jak vybrat skútr</a
						>
					</li>
					<li>
						<a href="/scooter-tuning-jaknato/" title="Návody a tipy pro opravy a servis skútru svépomocí. Nasajte dlouholeté zkušenosti profíků" class="hover:underline"
							>Návody a tipy</a
						>
					</li>
					<li><a href="/blog/" title="Články, zajímavosti a novinky ze světla skútrů." class="hover:underline">Blog</a></li>
				</ul>
			</div>

			<!-- ADDRESS -->
			<div class="w-full border-b border-white border-opacity-10 sm:border-0">
				<div
					class="flex cursor-pointer items-center gap-2.5 py-3 leading-none sm:flex-col sm:items-start sm:p-0"
					on:click={() =>
						activeFooterItem === 'address'
							? (activeFooterItem = null)
							: (activeFooterItem = 'address')}
					on:keydown
					on:keypress
					on:keyup
				>
					<Heading
						tag="strong"
						class={cn(
							'text-xl transition-colors sm:text-2xl',
							activeFooterItem === 'address' ? 'text-yellow' : 'text-white'
						)}>Kde nás najdete</Heading
					>
					<Dots
						class={cn(
							'h-2 transition-colors smmax:translate-y-[0.35rem]',
							activeFooterItem === 'address' ? 'text-white' : 'text-yellow'
						)}
					/>
					<ChevronDown
						class={cn(
							'ml-auto w-4 text-white transition-transform sm:hidden',
							activeFooterItem === 'address' && 'rotate-180'
						)}
					/>
				</div>

				<div
					class={cn(
						'my-4 flex-col gap-y-3 leading-normal sm:mb-0 sm:!flex',
						activeFooterItem === 'address' ? 'flex' : 'hidden'
					)}
				>
					<p class="mb-5">
						Sladovnická 15<br />
						620 00 Brno<br />
						<a
							href="https://maps.app.goo.gl/LcVc2XpaZz7RZb546"
							rel="noreferrer"
							target="_blank"
							class="text-primary mt-2 block hover:underline">zobrazit mapu</a
						>
					</p>
					<div class="mb-5 w-full">
						<Envelope class="mr-2 inline-block w-4 text-yellow" />
						<a href="mailto:info@scootland.cz" class="hover:underline">info@scootland.cz</a>
						<br />
						<Phone class="mr-2 inline-block w-4 text-yellow" />
						<a href="tel:+420515535535" class="hover:underline">+420 515 535 535</a>
					</div>
					<p class="mb-5 w-full">
						po-pá 9-12 13-18<br />
						so-ne zavřeno
					</p>
					<div class="flex items-center text-center">
						<Facebook class="mr-2 w-5 text-yellow" />
						<a
							href="https://www.facebook.com/scootland"
							rel="noreferrer"
							target="_blank"
							class="hover:underline">Facebook</a
						>
					</div>
				</div>
			</div>
		</div>

		<div
			class="container mx-auto flex w-full flex-col items-center text-center sm:flex-row-reverse sm:justify-between sm:gap-x-4 sm:border-t sm:border-white sm:border-opacity-10 sm:py-7"
		>
			<img alt="Platební metody" src={footer_payments} class="mb-10 sm:mb-0" />

			<div class="sm:text-left sm:text-sm sm:leading-relaxed">
				<p class="mb-4 sm:mb-0">ver. {version}</p>
				<p>Copyright © 2008–{new Date().getFullYear()} Scootland – skútry, tuning, servis.</p>
				<p>
					Obsah těchto stránek nesmí být reprodukován nebo publikován bez předchozího písemného
					souhlasu.
				</p>
			</div>
		</div>
	</footer>
{/if}
